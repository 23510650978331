.blogSecondLastPageContainer {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.LastFirstCard {
    width: 76%;
    height: 85%;
    display: flex;
    justify-content: space-between;
}

.LastSecondCard {
    width: 76%;
    height: 45%;
    display: flex;
    justify-content: space-between;
}

.LastCardHeader {
    width: 30%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.SecondSectionHeading{
    font-size: 21px;
    word-wrap: break-word;

}

.LastCardText {
    width: 30%;
    height: 100%;
    font-size: 14px;
    word-wrap: break-word;
}

.headingSection {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LastFirstCardImage {
    width: 32%;
    height: 100%;
    background-size: cover;
}

.LastSecondCardImage {
    width: 34%;
    height: 100%;
    background-image: url(https://cache.desktopnexus.com/thumbseg/2261/2261399-bigthumbnail.jpg);
    background-size: cover;
}
.delateButton{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 2%;
    color: #d3d3d3;
    font-size: 20px;
}

.LastFirstCardMob {
    width: 76%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LastSecondCardMob {
    width: 76%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blogSecondLastPageContainerMob {
    width: 100%;
    height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.LastFirstCardMob {
    width: 90%;
    height: 48%;
    display: flex;
    justify-content: space-between;
}

.LastSecondCardMob {
    width: 90%;
    height: 48%;
    display: flex;
    justify-content: space-between;
}

.LastSecondCardMob {
    width: 90%;
    height: 50%;
    display: flex;
    justify-content: space-between;
}

.LastCardHeaderMob {
    width: 100%;
    height: 35%;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.headingSectionMob {
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LastCardTextMob {
    width: 100%;
    height: 29%;
    font-size: 14px;
}

.LastFirstCardImageMob {
    width: 100%;
    height: 33%;
    background-image: url(https://th.bing.com/th/id/OIP._hpC-igCwQ1rz8VC9Sj4UgHaEo?w=308&h=192&c=7&r=0&o=5&dpr=1.3&pid=1.7);
    background-size: cover;
}

.LastSecondCardImageMob {
    width: 100%;
    height: 33%;
    background-image: url(https://th.bing.com/th/id/OIP.9tOB7qQ3Rk7MRhtwMTNxmgAAAA?pid=ImgDet&w=474&h=296&rs=1);
    background-size: cover;
}

.blogSecondLastPageContainerTab {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.tabBlogStyle{
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: space-between;
}
.LastCardHeaderTab{
    width: 48%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.LastFirstCardImageTab{
    width: 48%;
    height: 100%;
    background-image: url(https://th.bing.com/th/id/OIP._hpC-igCwQ1rz8VC9Sj4UgHaEo?w=308&h=192&c=7&r=0&o=5&dpr=1.3&pid=1.7);
    background-size: cover;
}
.LastSecondCardImageTab{
    width: 48%;
    height: 100%;
    background-image: url(https://th.bing.com/th/id/OIP.9tOB7qQ3Rk7MRhtwMTNxmgAAAA?pid=ImgDet&w=474&h=296&rs=1);
    background-size: cover;
}
.LastFirstCardTab {
    width: 90%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.headingSectionTab {
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.aiFillEdit{
    margin-bottom: 16px;
    text-decoration: none;
}