.fullpage-second{
    width: 100%;
    height: 680px;
    background-color: #ffffff;
}
.HDsecondpage-header{
    width: 100%;
    height: 14%;
    /* background-color: blue; */
    font-family: var(--extraBold);
    font-size: 32px;
    color: #6e2fff;
    display: flex;
    justify-content: center;
    align-items: end;
}
.HDthree-boxes{
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
}
.HDthree-boxes-div{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    justify-content: space-evenly;
}
.HDfirst-box{
    width: 300px;
    height: 230px;
    background-color: #EEF3FF;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.HDinside-div{
    width: 220px;
    height: 140px;
    position: absolute;
    top: -55px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.HDinside-div img{
    width: 120px;
    height: 120px;
}
.HDsecond-box{
    width: 300px;
    height: 230px;
    background-color: #EEF3FF;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.HDthird-box{
    width: 300px;
    height: 230px;
    background-color: #EEF3FF;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HDlearn-text{
    width: 100%;
    height: 19%;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: var(--extraBold);
    padding-right: 14px;
    color: #00277f;
    font-size: 12px;

    
}
.HDbig-text{
    width: 100%;
    height: 20%;
    font-family: var(--semiBold);
    font-size: 10px;
    display: flex;
    justify-content: center;
    padding-top: 12px;
}
.HDlearn-text-second{
    width: 100%;
    height: 19%;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: var(--extraBold);
    padding-right: 14px;
    color: #00277f;
    font-size: 12px;
    
}
.HDlearn-text-third{
    width: 100%;
    height: 19%;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: var(--extraBold);
    padding-right: 14px;
    color: #00277f;
    font-size: 12px;

    
}
.percentage-numbers{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.percentage-div{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;

}
.reduction-div{
    width: 28%;
    height: 80%;
    font-family: var(--extraBold);
    font-size:70px;
    background: linear-gradient(to bottom, #6e2fff 0%, #00277f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}
.text-reduction{
    width: 100%;
    height: 20%;
    font-size: 10px;
    font-family: var(--semiBold);
    display: flex;
    justify-content: center;
    
}

.higher-div{
    width: 28%;
    height: 80%;
    font-family: var(--extraBold);
    font-size:70px;
    background: linear-gradient(to bottom, #6e2fff 0%, #00277f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}
.no-fee{
    width: 28%;
    height: 80%;
    font-family: var(--extraBold);
    font-size:70px;
    background: linear-gradient(to bottom, #6e2fff 0%, #00277f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}
@media (min-width : 55px) and (max-width : 990px){
    .fullpage-second{
        width: 100%;
        height: 1480px;
        background-color: #ffffff;
    }

    .HDsecondpage-header{
        width: 100%;
        height: 12%;
        font-family: var(--extraBold);
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .HDthree-boxes{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
    }
    .HDthree-boxes-div{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .percentage-numbers{
    width: 100%;
    height: 47%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    }
    .percentage-div{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .reduction-div{
        width: 80%;

    }
    .higher-div{
        width: 80%;
    }
    .no-fee{
        width: 80%;

    }
    .HDfirst-box{
        width: 230px;
        text-align: center;
    }
    .HDsecond-box{
        width: 230px;
        text-align: center;
    }
    .HDthird-box{
        width: 230px;
        text-align: center;
    }
}