.title-main{
    color:#6e2fff;
    font-size: 38px;
}
.text-sm{
    font-size: 19px;
}
.bg-light-blue{
    background-color: #eef3ff;
}
.text-blue-dark{
    color: #00277f;
    font-size: 20px;
}
.font-extra-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.font-medium-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.font-small-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.explore-img-container{
    width: 100px;
    height: 100px;
}
.violet-blue{
    color: #3d03b8;
}
.bg-violet-blue{
    background-color: #1446b7;
}