.mainContainer {
  background-image: url("../../../../src/Assets/images/Skilled\ developer.jpg");
  // background:linear-gradient(to right,#f7ffff,#d0dff5);
  background-size: cover;
  background-repeat: no-repeat;
  height: 720px;
  width: 100%;
  // background-position: center;
  @media screen and (max-width: 768px) {
    background-image: url("../../../../src/Assets/images/happy-young-woman-sitting\ purple\ 1.jpg");
    background-size: cover;
    background-position: center;
  }
}

.HeroInitial-container {
  padding: 60px 200px 0px 160px;
  display: flex;
  justify-content: flex-end !important;
  flex-direction: column;
  display: flex;
  z-index: 0;
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
}
.HeroImage-container {
  min-width: 289px;
  max-width: 656px;
  height: 635px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -60px;
  @media screen and (max-width: 576px) {
    min-width: 322px;
    max-width: 659px;
    height: 270px;
    margin-top: -100px;
    margin-left: -40px;
    filter: opacity(0.1);
  }
  @media screen and (min-width: 1650px) and (max-width: 2570px) {
    margin-top: -20px;
    max-width: 980px;
  }
}
.HeroTxt-1 {
  color: #00277f;
  font-size: 19px;
  font-family: var(--medium);
  margin-bottom: 19px;
  @media screen and (min-width: 320px) and (max-width: 780px) {
    font-size: 17px;
    display: none;
  }
}
.HeroTxt-container {
  padding: 100px 100px 0px 160px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: 320px) and (max-width: 780px) {
    padding: 20px;
    margin-top: 50px;
  }
}
.HeroTxt-main {
  font-family: var(--extraBold);
  font-size: 42px;
  color: #6e2fff;
  line-height: 3.5rem;
  font-family: var(--extraBold);
  font-weight: bold;
  margin-bottom: 20px;
  @media screen and (max-width: 1050px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 768px) {
    line-height: 2.2rem;
    font-size: 30px !important;
    margin-bottom: 20px;
  }
}
.HeroTxt-sub {
  font-family: var(--semiBold);
  font-size: 11px;
  color: #00277f;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}
.GetAProofBtn {
  margin-top: 30px;
  color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ffffff;
  height: 40px;
  font-size: 10px;
  font-family: var(--semiBold);
  outline: none !important;
  background: linear-gradient(45deg,#6e2fff, #3b82f6);
  width: 230px !important;
}
.GetAProofBtn:hover {
  // background: linear-gradient(global.$primaryGradient);
  font-family: var(--extraBold);
  margin-top: 30px;
  font-size: 10px;
}
