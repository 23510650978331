
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Tektur:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap');
.title-main{
    color:#6e2fff;
    font-size: 38px;
}
.text-sm{
    font-size: 19px;
}
.bg-light-blue{
    background-color: #eef3ff;
}
.text-blue-dark{
    color: #00277f;
    font-size: 20px;
}
.text-blue-dark.font-medium-bold{
    color: #005b7f;
    font-style: italic;
}
.font-extra-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.font-medium-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.font-small-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.explore-img-container{
    width: 85px;
    height: 85px;
}
.violet-blue{
    color: #3d03b8;
}
.bg-violet-blue{
    background-color: #1446b7;
}
.bg-light{
    background-color: hsla(0, 0%, 98%, 0.911)
  }
  .sub-title{
    font-family: 'Montserrat', sans-serif;
    color: #2b56ba;
    font-weight: 600;
  }
  .card-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
 .cstm.card-title{
    font-size: 22px;
    font-weight: 600;
}
