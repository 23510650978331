/* .cstm-shadow::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 12px;
  background-color: #646464;
  top: 0;
  left: 0;
  z-index: 10;

} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&family=Tektur:wght@500&display=swap');
@font-face {
    font-family: "ChildrenFont";
    src: url("../../Assets/Fonts/blow.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  .title-main{
    color:#6e2fff;
    font-size: 38px;
}
.text-sm{
    font-size: 19px;
}
.bg-light-blue{
    background-color: #eef3ff;
}
.text-blue-dark{
    color: #00277f;
    font-size: 20px;
}
.font-extra-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.font-medium-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.font-small-bold{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.explore-img-container{
    width: 100px;
    height: 100px;
}
.violet-blue{
    color: #3d03b8;
}
.bg-violet-blue{
    background-color: #1446b7;
}
.cstm-shadow {
  box-shadow: 0 3px 10px rgba(155, 155, 155, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  isolation: isolate;
  border-radius: 12px;
  min-height: 200px;
}
.cstm-shadow:hover {
  box-shadow: 0 3px 10px rgba(36, 36, 36, 0.3);
  transform: translateY(-8px);
}
.bg-light-blue {
  background-color: #eef3ff;
}
.bg-light-red {
  background-color: #fff1ee;
}
.bg-light-green {
  background-color: #eeffee;
}
.bg-light-yellow {
  background-color: #ffeeff;
}
.child-img-container {
  height: 180px;
}
.child-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.font-italic {
  font-style: italic;
  color: teal;
}
.kids-coding-img {
  width: 85px;
  height: 85px;
}
.bg-img1 {
  background-image: url("../../Assets/images/offersectionlearning.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img2 {
  background-image: url("../../Assets/images/offersectionteacher.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img3 {
  background-image: url("../../Assets/images/offersectiononlineoffline.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img4 {
  background-image: url("../../Assets/images/offersectioninteracitactivity.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img5 {
  background-image: url("../../Assets/images/offersectioncertificates.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.cstm-shadow::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: rgba(92, 92, 92, 0.582); */
  inset: 0 auto auto 0;
  z-index: -1;
  border-radius: 12px;
}
.text-warning {
  color: #fdff97;
  font-weight: 500;
}
.main-card {
  box-shadow: 0 3px 10px rgba(36, 36, 36, 0.15);
  transition: all 0.2s ease;
  border-radius: 12px;
}
.main-card:hover {
  transform: scale(1.05);
  box-shadow: 0 3px 10px rgba(36, 36, 36, 0.3);
}
.cstm-img-shadow {
  background-color: rgba(0, 0, 0, 0.574);
  min-height: 100px;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.font-children{
    font-family: ChildrenFont,sans-serif;
}
.bg-light{
  background-color: hsla(0, 0%, 98%, 0.911)
}
.card-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
