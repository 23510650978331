.course-page{
    padding: 40px;
    background: #e9ecef;
} 
.course-colum{
    margin-bottom: 18px;
    /* height: 50px; */
}
.cardBtn{
    color: #fff !important;
    font-weight: 700;
    font-style: var(--medium);
}
.course-text{
    font-family: var(--regular);
    font-weight: 500;
}    
.course-mode{
   font-family: var(--regular);
   font-weight: 200;
   color: #ae9c9c;
   margin-bottom: 10px;
}