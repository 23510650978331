.secondBlogSecondStart {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.secondAreaAddDiv {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
}

.secondAreaAdd {
    width: 76%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--extraBold);
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BlogUnderLine {
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondBlogSecondBlogBoxes {
    width: 24%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.secondBlogSecondBoxesDiv {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondBlogSecondBlogBoxesMainDiv {
    width: 76%;
    height: 90%;
    display: flex;
    justify-content: space-between;
}

.secondBlogSecondBoxHeaderText {
    font-size: 11px;
    word-wrap: break-word;
}

.secondBlogSecondBoxHeader {
    width: 100%;
    height: 30%;
    font-size: 18px;
    font-weight: 700;
    word-wrap: break-word;
}

.pictureDiv {
    width: 100%;
    height: 45%;
    background-size: cover;
    border-radius: 8px;
}
.secondBlogSecondImage{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.ImageDiv2 {
    width: 100%;
    height: 45%;
    background-image: url(https://w0.peakpx.com/wallpaper/446/291/HD-wallpaper-mars-red-planet-earth-planets-solar-system-earth-and-mars-distance.jpg);
    background-size: cover;
    border-radius: 8px;
}

.pictureDiv3 {
    width: 100%;
    height: 45%;
    background-image: url(https://akm-img-a-in.tosshub.com/indiatoday/images/story/202303/super_earth_c-sixteen_nine.jpg?VersionId=XixlcL2xiSDU2HUniBVimMoa1C.M1BNP);
    background-size: cover;
    border-radius: 12px;
}

.pictureDiv4 {
    width: 100%;
    height: 45%;
    background-image: url(https://hips.hearstapps.com/hmg-prod/images/gettyimages-1354937085-641c7c4ad4e4e.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*);
    background-size: cover;
    border-radius: 8px;
}

.secondBlogSecondStartMob {
    width: 100%;
    height: 1700px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.secondAreaAddDivMob {
    width: 100%;
    height: 2.5%;
    display: flex;
    justify-content: center;
}

.secondAreaAddMob {
    width: 90%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--extraBold);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondBlogSecondBoxesDivMob {
    width: 100%;
    height: 92%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondBlogSecondBlogBoxesMainDivMob {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.secondBlogSecondBlogBoxesMob {
    width: 100%;
    height: 24%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.secondBlogSecondBoxHeaderMob {
    width: 100%;
    height: 18%;
    font-size: 18px;
    font-weight: 700;
}

.secondBlogSecondStartTab {
    width: 100%;
    height: 900px;
    flex-direction: column;
    justify-content: space-around;
}

.secondAreaAddDivTab {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: center;
}

.secondBlogSecondBoxesDivTab {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondBlogSecondTabStyle {
    width: 100%;
    height: 48%;
    display: flex;
    justify-content: space-between;
}
.secondBlogSecondBlogBoxesTab {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}