.fullpage-HDthird {
    max-width: 100%;
    height: 450px;
    background-color: #f0f3fc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
}

.HDthird-header {
    width: 920px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.HDthird-selectInput {
    width: 100%;
    height: 45px;
    font-size: medium;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 9px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../Assets/downarrow.png');
    appearance: none;
    background-repeat: no-repeat;
    background-position: 96%;
    padding-left: 20px;
    color: #6A85B6;
    background-color: white;
}
@media (min-width : 575px) and (max-width : 992px){
    .HDthird-header {
        width: 500px;
    }
    .HDthird-selectInput {
        font-size: smaller;
        background-color: white;

    }
}
@media (max-width : 575px){
    .fullpage-HDthird{
        height: 350px;
    }
    .HDthird-header {
        width: 80%;
    }
    .HDthird-selectInput {
        font-size: 5.5px;
        height: 35px;
        background-color: white;

    }
}