.LastPageStart{
    width: 100%;
    height: 90vh;
    /* background-color: yellowgreen; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    
}
.Last-FirstCard{
    width: 76%;
    height: 31%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;

}
.Last-SecondCard{
    width: 76%;
    height: 31%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;

}
.Last-ThirdCard{
    width: 76%;
    height: 31%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
}
.Last-CardHeader{
    width: 32%;
    height: 100%;
    background-color: #e9e9e9;
    font-family: var(--bold);
    font-size: 20px;
    text-align: center;
}
.Last-FirstCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("https://media.istockphoto.com/id/691610536/photo/aucklands-skyline-at-dawn.jpg?b=1&s=170667a&w=0&k=20&c=-Ok-i87LMHAOdoUrcWps4GPcsPrGS8NcTUndYrdpZ2o=");
    background-size: cover;
}
.Last-SecondCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("https://i.natgeofe.com/n/3687bca0-5bbb-429a-88d5-9b68b9780bbe/new-zealand-australia-travel-guides_3x2.jpg");
    background-size: cover;
}
.Last-ThirdCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("https://cdn.theatlantic.com/media/img/photo/2020/11/photos-scenes-new-zealand/a01_153815173-1/original.jpg");
    background-size: cover;
}
.Last-CardText{
    width: 32%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--semiBold);
    font-size: 12px;
    text-align: center;

}
@media (min-width : 55px) and (max-width : 990px){
    .LastPageStart{
        width: 100%;
        height: 230vh;
        /* background-color: yellowgreen; */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        
    }
    .Last-FirstCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .Last-CardHeader{
        width: 100%;
        height: 25%;
        background-color: #e9e9e9;
        font-family: var(--bold);
        font-size: 20px;
        text-align: center;
    }
    .Last-FirstCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("../Assets/images/demo.jpg");
        background-size: cover;
    }
    .Last-CardText{
        width: 100%;
        height: 30%;
        background-color: #c9c9c9;
        font-family: var(--semiBold);
        font-size: 12px;
        text-align: center;
    
    }
    .Last-SecondCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .Last-ThirdCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .Last-SecondCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("https://hips.hearstapps.com/hmg-prod/images/sunset-quotes-21-1586531574.jpg?crop=1.00xw:0.752xh;0,0.0601xh&resize=1200:*");
        background-size: cover;
    }
    .Last-ThirdCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("https://cdn.vox-cdn.com/thumbor/Al48-pEnyIn2rlgKX7MIHNmlE68=/0x0:5563x3709/1200x800/filters:focal(2302x1311:3192x2201)/cdn.vox-cdn.com/uploads/chorus_image/image/65752607/1048232144.jpg.0.jpg");
        background-size: cover;
    }
}