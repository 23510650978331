.secondpage-start {
  width: 100%;
  background: linear-gradient(to right, #e4ebfc 0%, #f5f8ff 100%);
  padding: 100px 40px 100px 40px;

}
.secondpage-header {
  width: 100%;
  height: 15%;
  font-family: var(--extraBold);
  font-size: 35px;
  text-align: center;
  display: flex;
  align-items: end;
  justify-content: center;
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}
.second-text-build {
  width: 100%;
  font-family: var(--medium);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #00277f;
  margin-bottom: 50px;
}
.third-text-semikolen {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--medium);
    font-weight: 500;
    margin-bottom: 20px;
}
.three-boxes {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
}
.three-boxes-div {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  /* flex-wrap: wrap; */
}
.left-icon {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.guided-projects-div {
  width: 240px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 2px solid #f1f1f1;
  gap: 9px;
  text-align: center;

}
.guided-projects {
  width: 80%;
  height: 45px;
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  border-radius: 0px 0px 20px 20px;
  font-size: 16px;
  font-family: var(--bold);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guided-image {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guided-image img {
  width: 100px;
  height: 100px;
}
.work-text {
  width: 100%;
  height: 13%;
  font-size: 13px;
  font-family: var(--bold);
  display: flex;
  justify-content: center;
}
.build-text {
  width: 94%;
  height: 12%;
  font-size: 11px;
  font-family: var(--medium);
}
.mongo-react-ui {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.mongo {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 2px solid #f1f1f1;
}
.react {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 2px solid #f1f1f1;
}
.ui {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 2px solid #f1f1f1;
}
.express-node {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.express {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 2px solid #f1f1f1;
}
.node {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.independent-projects-div {
  width: 260px;
  height: 330px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 2px solid #f1f1f1;
  gap: 9px;
}
.independent-projects {
  width: 80%;
  height: 45px;
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  border-radius: 0px 0px 20px 20px;
  font-size: 15px;
  font-family: var(--bold);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.indepentend-image {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.indepentend-image img {
  width: 100px;
  height: 100px;
}
.ott-text {
  width: 93%;
  height: 13%;
  font-size: 13px;
  font-family: var(--bold);
  // display: flex;
  // justify-content: center;
  text-align: center;
}
.video-text {
  width: 94%;
  height: 10%;
  font-size: 11px;
  font-family: var(--medium);
  text-align: center;
}
.rest-react-ui {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.rest {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.react {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.ui {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.express-node {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.express {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.node {
  width: 30%;
  height: 90%;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--medium);
  border: 1px solid #6e2fff;
}
.last-button-div {
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: end;
}
#button-secondpage {
    height: 45px;
    width: 140px;
    background: linear-gradient(45deg,#3b82f6, #9333ea);
    font-family: var(--semiBold);
    font-size: 11px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    margin-left: 30px;
    outline: none !important;
    cursor: pointer;
    @media screen and (max-width: 989px) {
      font-size: 10px;
      width: 100px;
    }
    &:hover {
      font-size: 12px;
      // background: linear-gradient(global.$primaryGradient);
    }
}
.right-icon {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 55px) and (max-width: 990px) {
  .secondpage-start {
    height: 1300px;
    background: linear-gradient(to right, #e4ebfc 0%, #f5f8ff 100%);

    padding: 60px 20px 60px 20px;
  }
  .second-text-build {
    width: 100%;
    height: 6%;
    font-family: var(--bold);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #00277f;
    /* background-color: yellow; */
  }
  .third-text-semikolen {
    /* display: none; */
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    font-family: var(--medium);
    font-weight: 550;
    font-size: 12px;
    margin-bottom: 30px;
  }
  .three-boxes {
    width: 100%;
    height: 67%;
  }
  .left-icon {
    display: none;
  }
  .right-icon {
    display: none;
  }
  .three-boxes-div {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
  .independent-projects-div {
    width: 240px;
    // height: 280px;
  }
  .guided-projects-div {
    width: 240px;
    height: 280px;
  }

  .last-button-div {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: end;
    /* background-color: yellow; */
  }
  .secondpage-header {
    width: 100%;
    height: 12%;
    font-family: var(--extraBold);
    font-size: 19px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #6e2fff;
    /* background-color: red; */
  }
}

/*extra added?=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
