
.coursesTrialContainer{
    max-width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coursesTrial{
    color: #3d03b8;
    text-align: center;
}

.coursesTrialMainDiv{
    width: 87%;
    height: 75%;
}
.coursesTrialButtonMain{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-around;
}
.coursesTrialButton{
    height: 35%;
    width: 100%;
}
.coursesTrialSecondButton{
    background-color: white;
    border-style: solid;
    border-color: #6a2afe;
    color: #6a2afe;
    background-color: white;
    font-size: smaller;
}
.coursesTrialMainDivTab{
    height: 70%;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.coursesTrialButtonMainTab{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.coursesTrialButtonMainLinkTab{
    width: 48%;
}
.coursesTrialSecondButtonMainLinkTab{
    width: 48%;
    display: flex;
    justify-content: end;
}
.coursesTrialButtonMainTab{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.coursesTrialButtonTab{
    width: 97%;
}
.coursesTrialSecondButtonTab{
    background-color: white;
    border-style: solid;
    border-color: #6a2afe;
    color: #6a2afe;
    background-color: white;
    font-size: smaller;
    width: 97%;
}
.coursesTrialButtonMainLinkLap{
    width: 47%;
}
.coursesTrialButtonLap{
    width: 97%;
}
.coursesTrialSecondButtonMainLinkLap{
    width: 47%;
}
.coursesTrialButtonLap{
    width: 400px;
}
.coursesTrialButtonsLap{
    width: 97%;
}
.coursesTrialMainDivLap{
    height: 70%;
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}
.coursesTrialSecondButtonsLap{
    background-color: white;
    border-style: solid;
    border-color: #6a2afe;
    color: #6a2afe;
    background-color: white;
    font-size: smaller;
    width: 97%;
}