.FifthPageStart{
    width: 100%;
    height: 80vh;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.FifthBlogBoxesDiv{
    width: 100%;
    height: 65%;
    /* background-color: yellowgreen; */
    
    display: flex;
    justify-content: center;
    align-items: center;

}
.FifthBlogBoxes{
    width: 76%;
    height: 95%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    
}
.FifthBlogBox{
    width: 24%;
    height: 100%;
    background-color: #e9e9e9;
    border-radius: 12px;
}
.Fifthpicture-div{
    width: 100%;
    height: 40%;
    background-image: url("https://cdn.mos.cms.futurecdn.net/xcLR5HMU2kxskdAy3ZVuTf-1200-80.jpg");
    background-size: cover;
    border-radius: 12px;
}
.FifthImage-div2{
    width: 100%;
    height: 40%;
    background-image: url("https://w0.peakpx.com/wallpaper/446/291/HD-wallpaper-mars-red-planet-earth-planets-solar-system-earth-and-mars-distance.jpg");
    background-size: cover;
    border-radius: 12px;
}
.Fifthpicture-div3{
    width: 100%;
    height: 40%;
    background-image: url("https://akm-img-a-in.tosshub.com/indiatoday/images/story/202303/super_earth_c-sixteen_nine.jpg?VersionId=XixlcL2xiSDU2HUniBVimMoa1C.M1BNP");
    background-size: cover;
    border-radius: 12px;
}
.Fifthpicture-div4{
    width: 100%;
    height: 40%;
    background-image: url("https://hips.hearstapps.com/hmg-prod/images/gettyimages-1354937085-641c7c4ad4e4e.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*");
    background-size: cover;
    border-radius: 12px;
}
.FifthBlogBoxHeader{
    width: 100%;
    height: 25%;
    /* background-color: aqua; */
    font-size: 16px;
    font-family: var(--bold);
}
.FifthBlogBoxTextes{
    width: 100%;
    height: 35%;
    font-size: 10px;
    font-family: var(--bold);
    text-align: justify;

}
.FifthAreaAddDiv{
    width: 100%;
    height: 30%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
}
.FifthAreaAdd{
    width: 76%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--extraBold);
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width : 55px) and (max-width : 990px){
    .FifthSecondPageStart{
        width: 100%;
        height: 200vh;
        /* background-color: aquamarine; */
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    .FifthBlogBoxesDiv{
        width: 100%;
        height: 100%;
        /* background-color: yellowgreen; */
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .FifthBlogBoxes{
        width: 100%;
        height: 100%;
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .FifthAreaAddDiv{
       display: none;
    }
    .FifthBlogBox{
        width: 300px;
        height: 24%;
        background-color: #e9e9e9;
        border-radius: 12px;
        text-align: center;
    }
    .FifthBlogBoxTextes{
        width: 100%;
        height: 35%;
        font-size: 10px;
        font-family: var(--bold);
        text-align: center;
    
    }
    .FifthPageStart{
        height: 180vh;
    }
}