.UnderTextSignUp{
    text-decoration: none;
    text-align: center;
    font-size: small;
    font-weight: 600;
    color: black;
}
.serviceSignUps{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 400px;
}
.servicesDivMain{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.servicesDivLapMain{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 30%;
}
.ant-input{
    height: 100%;
}
.ant-input-password{
    height: 100%;
}
.continueDivSignUpTab{
    display: flex;
    width: 100%;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    font-size: small;
    font-weight: bold;
    height: 40px;
    justify-content: space-evenly;
}