.Thirdpage-Starting {
  width: 100%;
  padding: 100px 40px 100px 40px;
}
.heading-thirdpage {
  width: 100%;
  height: 15%;
  font-family: var(--extraBold);
  font-size: 35px;
  text-align: center;
  display: flex;
  align-items: end;
  justify-content: center;
  background: linear-gradient(45deg, #3b82f6, #9333ea);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}
.secondtext-thirdpage {
  width: 100%;
  font-family: var(--medium);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #00277f;
  margin-bottom: 50px;
}
.four-boxes {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 452px;
}
.four-boxes-div {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  justify-content: space-evenly;
}
.first-box {
  width: 250px;
  height: 300px;
  background-color: #ecebff;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inside-div {
  width: 220px;
  height: 140px;
  background-color: white;
  position: absolute;
  top: -70px;
  border-radius: 12px;
  border: 0.5px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inside-div img {
  width: 120px;
  height: 120px;
}
.second-box {
  width: 250px;
  height: 300px;
  background-color: #ecebff;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.third-box {
  width: 250px;
  height: 300px;
  background-color: #ecebff;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fourth-box {
  width: 250px;
  height: 300px;
  background-color: #ecebff;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.learn-text {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  font-family: var(--bold);
  padding-right: 14px;
  color: #00277f;
}
.learn-text-second {
  width: 100%;
  height: 21%;
  display: flex;
  justify-content: center;
  font-family: var(--bold);
  padding-right: 14px;
  font-size: 15px;
  color: #00277f;
}
.big-text {
  width: 100%;
  height: 20%;
  font-family: var(--semiBold);
  font-size: 13px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
}
.learn-text-third {
  width: 100%;
  height: 18%;
  display: flex;
  justify-content: center;
  font-family: var(--bold);
  padding-left: 16px;
  font-size: 15px;
  color: #00277f;
}
.learn-text-fourth {
  width: 100%;
  height: 18%;
  display: flex;
  justify-content: center;
  font-family: var(--bold);
  padding-left: 16px;
  font-size: 15px;
  color: #00277f;
}
@media (min-width: 55px) and (max-width: 990px) {
  .Thirdpage-Starting {
    width: 100%;
    height: 1650px;
  }
  .heading-thirdpage {
    width: 100%;
    height: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--extraBold);
    font-size: 25px;
    color: #6e2fff;
    text-align: center;
  }
  .secondtext-thirdpage {
    width: 100%;
    height: 8%;
    font-family: var(--bold);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    color: #1b1b1b;
  }
  .four-boxes {
    width: 100%;
    height: 86%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: none;
  }
  .four-boxes-div {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
  .first-box {
    width: 230px;
    height: 280px;
    background-color: #ecebff;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .inside-div {
    width: 200px;
    height: 140px;
    background-color: #ecebff;
    position: absolute;
    top: -70px;
    border-radius: 12px;
    border: 0.5px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inside-div img {
    width: 120px;
    height: 120px;
  }
  .second-box {
    width: 230px;
    height: 280px;
    background-color: #ecebff;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .third-box {
    width: 230px;
    height: 280px;
    background-color: #ecebff;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .fourth-box {
    width: 230px;
    height: 280px;
    background-color: #ecebff;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
