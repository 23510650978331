.first-page {
  width: 100%;
  min-height: 90vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 60px 40px 80px 40px;
}
.future-main-head {
  width: 100%;
  height: 16%;
  font-family: var(--extraBold);
  font-size: 40px;
  background: linear-gradient(48deg, #f13636, #3b82f6, #9333ea);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: end;
}
.embark-div {
  width: 100%;
  font-family: var(--semiBold);
  font-size: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: end;
  color: #00277f;
  margin-bottom: 50px;
}
.boy-text {
  width: 100%;
  height: 70%;
  display: flex;
}
.boy-image-div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.boy-image-div img {
  width: 600px;
  height: 370px;
}
.two-text {
  width: 50%;
  height: 80%;
}
.start-div {
  width: 78%;
  height: 55%;
  font-family: var(--bold);
  font-size: 30px;
  display: flex;
  align-items: end;
  justify-content: center;
  color: #00277f;
  /* background-color: red; */
}
.just-text-div {
  width: 100%;
  height: 50%;
  font-family: var(--medium);
  display: flex;
  align-items: start;
  justify-content: center;
//   font-size: 15px;

  /* background-color: aqua; */
}
.provide-div {
  width: 70%;
  height: 40%;
  background-color: #e9e9e9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--semiBold);
  color: #00277f;
  font-size: 13px;
}
@media (min-width: 55px) and (max-width: 990px) {
  .first-page {
    // height: 890px;
    padding: 60px 20px 60px 20px;
  }
  .question-mobile-div {
    width: 100%;
    height: 10%;
    margin-bottom: 20px;
    /* background-color: green; */
    font-size: 16px;
    font-family: var(--extraBold);
    font-weight: 700;
    color: #00277f;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .second-text-mobile {
    width: 100%;
    height: 20%;
    /* background-color: yellow; */
    margin-bottom: 30px;
    display: flex;
    color: #1b1b1b;
    font-family: var(--medium);
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    text-align: center;
  }
  .text-contain-div {
    // width: 90%;
    height: 90%;
    background-color: gray;
  }
  .three-info-mobile {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .three-info {
    padding: 20px;
    width: 300px;
    height: 80%;
    background-color: #e9e9e9;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .online-div {
    width: 33%;
    height: 100%;
    font-size: x-small;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-family: var(--thin);
    color: #00277f;
  }
  .online-div h6 {
    font-size: 10px;
    font-weight: 900;
    color: #1b1b1b;
    font-family: var(--extraBold);
  }
  .trial-div {
    width: 33%;
    height: 100%;
    font-size: x-small;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-family: var(--thin);
    color: #00277f;
  }
  .trial-div h6 {
    font-size: 10px;
    font-weight: 900;
    color: #1b1b1b;
    font-family: var(--extraBold);
  }
  .placement-div {
    width: 33%;
    height: 100%;
    font-size: x-small;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-family: var(--thin);
    color: #00277f;
  }
  .placement-div h6 {
    font-size: 10px;
    font-weight: 900;
    color: #1b1b1b;
    font-family: var(--extraBold);
  }
  .embark-text-mobile {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .embark-contain-mobile {
    padding: 15px;
    width: 95%;
    height: 80%;
    font-family: var(--extraBold);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b1b1b;
    background-color: #c9c9c9;
    border-radius: 8px;
  }
  .superpower-mobile {
    width: 100%;
    height: 15%;
    /* background-color: red; */
    font-family: "Montserrat-SemiBold";
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
  }

  .boy-image-mobile img {
    width: 320px;
    height: 210px;
    /* display: none; */
  }
  .boy-text {
    /* display: none; */
    display: flex;
    flex-direction: column;
    height: 40%;
  }
  .boy-image-mobile {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: center;
  }
  .future-main-mobile {
    width: 100%;
    height: 15%;
    font-family: var(--extraBold);
    font-size: 25px;
    color: #6e2fff;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .future-main-head {
    display: none;
  }
  .embark-div {
    display: none;
  }
  .two-text {
    width: 100%;
  }
  .start-mobile {
    width: 100%;
    height: 10%;
    /* background-color: red; */
    font-family: var(--extraBold);
    font-size: 25px;
    display: flex;
    align-items: center;
    color: #00277f;
    justify-content: center;
    margin-top: 20px;
  }
  .just-text-mobile {
    width: 100%;
    height: 18%;
    /* background-color: yellow; */
    font-size: 12px;
    font-family: var(--semiBold);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b1b1b;
    text-align: center;
  }
  .just-text-div {
    display: none;
  }
  .provide-mobile-div {
    width: 100%;
    height: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .provide-mobile {
    margin-top: 70px;
    width: 88%;
    height: 90%;
    background-color: #e9e9e9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--semiBold);
    color: #00277f;
    font-size: 12px;
    padding: 20px;
  }
  .boy-text {
    display: none;
  }
}
@media (min-width: 990px) and (max-width: 2840px) {
  .second-text-mobile {
    display: none;
  }
  .question-mobile-div {
    display: none;
  }
  .three-info-mobile {
    display: none;
  }
  .boy-image-mobile {
    display: none;
  }
  .future-main-mobile {
    display: none;
  }
  .embark-text-mobile {
    display: none;
  }
  .superpower-mobile {
    display: none;
  }
  .just-text-mobile {
    display: none;
  }
  .start-mobile {
    display: none;
  }
  .provide-mobile-div {
    display: none;
  }
}
