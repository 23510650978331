.main-nav{
    width: 100%;
    height: 100px;
    background-color: #e9e9e9;
    display: flex;
    /* position: fixed; */
}
.semilogos{
    width: 30%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    

}
.semiLogoOnly{
    width: 30%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: end;
    align-items: center;
}
.semiLogoOnly img{
    width: 50px;
    height: 50px;
}
.semiTextOnly{
    width: 67%;
    height: 100%;
    /* background-color: crimson; */
   

}
.semiTextOnly img{
    width: 170px;
    height: 100px;
    /* margin-top: 2px; */
}
.searchbar-div{
    width: 48%;
    height: 100%;
    /* background-color: chartreuse; */
    display: flex;
    justify-content: end;
    align-items: center;
}
 #searchbar-id{
    border-radius: 5px 0px 0px 5px;
    border: none;
    height: 30px;
    width:50%;
    /* background-color: white; */
    outline: none;
    font-size: small;
    text-indent: 20px;
    font-weight: 600;

}

.sign-button-div{
    width: 11%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: end;
    align-items: center;
}
.buttonsign{
    height: 40px;
    width: 130px;
    border-radius: 7px;
    transition: 0.5s;
    background-color: transparent;
    border: 1px solid gray;
}
.signblog{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    font-weight: 600;
    color: gray;
}
.avathar-div{
    width: 8%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.navFooter{
    width: 100%;
    height: 25px;
    background-color: #c9c9c9;
    display: flex;
    /* position: fixed; */
}
.home-icon-div{
    width: 20%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: end;
    align-items: center;
}
.student-div{
    width: 11%;
    height: 100%;
    /* background-color: yellowgreen; */
    font-size: 12px;
    font-family: var(--bold);
    display: flex;
    justify-content: end;
    align-items: center;
}
.activation{
    width: 8%;
    height: 100%;
    /* background-color: aqua; */
    font-size: 12px;
    font-family: var(--bold);
    display: flex;
    justify-content: end;
    align-items: center;
}
.date-div{
    width: 28%;
    height: 100%;
    /* background-color: gray; */
    font-size: 12px;
    font-family: var(--bold);
    display: flex;
    justify-content: end;
    align-items: center;
}
@media (min-width : 55px) and (max-width : 990px){
    .sign-button-div{
        display: none;
    }    
    .semiTextOnly{
        display: none;
    }
    .semiLogoOnly{
        width: 70%;
    }
    .searchbar-div{
        display: none;
    }
    .avathar-div{
        width: 100%;
        display: flex;
        justify-content: end;
    }
    .activation{
        display: none;
    }
    .student-div{
        display: none;
    }
    .date-div{
        width: 100%;
    }
}
