.ThirdPageStart{
    width: 100%;
    height: 90vh;
    /* background-color: yellowgreen; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    
}
.FirstCard{
    width: 76%;
    height: 31%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;

}
.SecondCard{
    width: 76%;
    height: 31%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;

}
.ThirdCard{
    width: 76%;
    height: 31%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
}
.CardHeader{
    width: 32%;
    height: 100%;
    background-color: #e9e9e9;
    font-family: var(--bold);
    font-size: 20px;
    text-align: center;
}
.FirstCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("../Assets/images/demo.jpg");
    background-size: cover;
}
.SecondCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("https://hips.hearstapps.com/hmg-prod/images/sunset-quotes-21-1586531574.jpg?crop=1.00xw:0.752xh;0,0.0601xh&resize=1200:*");
    background-size: cover;
}
.ThirdCardImage{
    width: 34%;
    height: 100%;
    /* background-color: darkred; */
    background-image: url("https://cdn.vox-cdn.com/thumbor/Al48-pEnyIn2rlgKX7MIHNmlE68=/0x0:5563x3709/1200x800/filters:focal(2302x1311:3192x2201)/cdn.vox-cdn.com/uploads/chorus_image/image/65752607/1048232144.jpg.0.jpg");
    background-size: cover;
}
.CardText{
    width: 32%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--semiBold);
    font-size: 12px;
    text-align: center;

}
@media (min-width : 55px) and (max-width : 990px){
    .ThirdPageStart{
        width: 100%;
        height: 230vh;
        /* background-color: yellowgreen; */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        
    }
    .FirstCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .CardHeader{
        width: 100%;
        height: 25%;
        background-color: #e9e9e9;
        font-family: var(--bold);
        font-size: 20px;
        text-align: center;
    }
    .FirstCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("../Assets/images/demo.jpg");
        background-size: cover;
    }
    .CardText{
        width: 100%;
        height: 30%;
        background-color: #c9c9c9;
        font-family: var(--semiBold);
        font-size: 12px;
        text-align: center;
    
    }
    .SecondCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .ThirdCard{
        width: 93%;
        height: 31%;
        /* background-color: yellow; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    
    }
    .SecondCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("https://hips.hearstapps.com/hmg-prod/images/sunset-quotes-21-1586531574.jpg?crop=1.00xw:0.752xh;0,0.0601xh&resize=1200:*");
        background-size: cover;
    }
    .ThirdCardImage{
        width: 100%;
        height: 45%;
        /* background-color: darkred; */
        background-image: url("https://cdn.vox-cdn.com/thumbor/Al48-pEnyIn2rlgKX7MIHNmlE68=/0x0:5563x3709/1200x800/filters:focal(2302x1311:3192x2201)/cdn.vox-cdn.com/uploads/chorus_image/image/65752607/1048232144.jpg.0.jpg");
        background-size: cover;
    }
}