.footer-container {
  padding: 100px;
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(0deg, rgb(4, 40, 111) 0%, rgb(4, 40, 111) 100%);
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
}
.list-container {
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}
.list-heading {
  font-size: 15px;
  font-family: var(--semiBold);
  font-weight: 700;
  padding-bottom: 25px;
  color: #a9bdf1;
}
.list-container ul {
  list-style-type: none;
  padding-left: 0px;
}
.list-container ul li {
  //   font-family: global.$Regular;
  margin-bottom: 25px;
  color: #97aee9;
  font-size: 16px;
  a {
    text-decoration: none;
    color: #97aee9;
  }
}
.commonlist {
  cursor: pointer;
}
.footer-logo {
  width: 200px;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 576px) {
    margin-left: -8px;
    margin-bottom: 12px;
  }
}
.ft-logoTxt {
  color: #a9bdf1;
  margin-left: 28px;
  line-height: 1.5rem;
  font-size: 16px;
  font-family: var(--regular);
  text-align: start;
  @media screen and (max-width: 576px) {
    margin-left: 2px;
    margin-bottom: 19px;
  }
}
.social-Icons {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 30px;
}
