.App {
    text-align: center;
  }
  
  .App-logo {
    height: 15vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 3s linear;
    }
  }
  
  .loading {
    background-color: #282c34;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: fixed;
    z-index: 100;
  }
  .loading.display-none {
    background-color: #282c34;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: fixed;
    position: fixed;
    z-index: -10;
    opacity: 0;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  #loader {
    --front-color: #00277f;
    --text-color: white;
    width: 64px;
    height: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #loader .text {
    position: absolute;
    bottom: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.9px;
  }
  
  #loader .text::before,
  #loader .text::after {
    content: attr(data-text);
  }
  
  #loader .text::before {
    color: var(--text-color);
  }
  
  #loader .text::after {
    color: var(--front-color);
    animation: text-animation76 3.6s ease infinite;
    position: absolute;
  }
  
  @keyframes text-animation76 {
    0% {
      clip-path: inset(0 100% 0 0);
    }
  
    50% {
      clip-path: inset(0);
    }
  
    100% {
      clip-path: inset(0 0 0 100%);
    }
  }