.CommentSection {
  position: relative;
 
}

.CommentBoxIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  cursor: pointer;
  
}

.Comments {
  margin-top: 16px;
  margin-bottom: 8px;
  
}

.Comment {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
  
}

.CommentUser {
  font-weight: bold;
 
}

.NoComments {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 8px;
}

.CommentBox {
  /* width: 100%; */
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  /* background-color: red; */
}

.CommentBox label {
  display: block;
  margin-bottom: 8px;
}

.CommentBox input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.CommentBox button {
  display: block;
  margin: 0 auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.CommentBox button:hover {
  background-color: #0062cc;
}
