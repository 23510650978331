.register-back-button {
  width: 100%;
  border: 1px solid #00277f;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 7px;
  color: #00277f;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.register-next-button {
  width: 100%;
  background-color: #00277f;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 7px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.register-next-button:hover {
  background-color: #03309a;
}

.FormElement {
  background-color: #f5f5f5;
  height: 60px;
  border-radius: 5px;
  margin-bottom: 30px;
  margin-right: 20px;
}
.FormLabel {
  padding-left: 10px;
  padding-top: 5px;
  font-size: 13px;
  font-weight: 600;
  color: grey;
}

.registration-body {
  min-height: 100vh;
}

.registration-footer {
  height: 100px;
  background-color: rgb(224, 224, 224);
}

.register-header {
  font-size: 40px;
  font-weight: 600;
  color: #00277f;
  margin-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.file-upload {
  background-color: #f5f5f5;
  height: 60px;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 25px;
  align-items: center;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: grey;
}

.file-upload:hover {
  color: rgb(102, 102, 102);
}

.imgsrc {
  background-image: url(../Assets/images/sahal.jpg);
  height: 90vh;
  background-size: 100% 100%;
  width: 100%;
}
.imgsrcdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 60px;
  font-weight: 600;
  color: aliceblue;
  text-align: center;
}
.termsandconditionsmaintexth1-Div {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 230px;
  text-align: center;
}
.termsandconditionsmainptextmain-Div {
  display: flex;
  width: 100%;
  justify-content: center;
}
.h1 {
  font-size: 40px;
  font-weight: 600;
}
.RegistrationDetails-Main {
  border: 0px solid;
  border-radius: 5px;
  box-shadow: rgb(218 217 221) 0px 0px 10px 0px;
  margin-left: 50px;
  padding-left: 90px;
  width: 90%;
}
.RegistrationDetailsImageUploader-Mobile {
  display: flex;
  justify-content: center;
}
.RegistrationDetailsall-Details {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.TermsAndConditionsMain-Div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.termsandcondition-Arrow {
  width: 40px;
}

@media (max-width: 576px) {
  .RegisterMain-Div {
    padding-left: 6px;
    padding-right: 6px;
  }
  .RegistrationDetails-Main {
    margin-left: 15px;
    padding-left: 0px;
  }
  .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .RegistrationDetailsImageUploader {
    display: none;
  }
  .RegistrationDetailsall-Details {
    display: flex;
    justify-content: center;
    width: 244px;
  }
  .RegistrationDetailsdetailsdata {
    margin-left: 8%;
  }
  .imgsrcdiv {
    font-size: 34px;
    font-weight: 600;
  }
  .registration-header {
    height: 0px;
  }
  .imgsrc {
    height: 40vh;
  }
  .termsandconditionsmaintexth1-Div {
    height: 133px;
    display: flex;
    justify-content: center;
  }
  .h1 {
    font-size: 17px;
    font-weight: 600;
  }
  .FormElement {
    margin-right: 0px;
  }
  .TermsAndConditionsMain-Div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .register-next-button {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: 25%;
  }
  .register-back-button {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: 25%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .RegisterMain-Div {
    margin-left: 64px;
  }
  .registration-body {
    padding-left: 0px;
    padding-right: 41px;
  }
  .RegistrationDetailsImageUploader-Mobile {
    display: none;
  }
  .registration-header {
    height: 0px;
  }
  .h1 {
    font-size: 21px;
    font-weight: 600;
  }
  .imgsrcdiv {
    font-size: 34px;
    font-weight: 600;
  }

  .TermsAndConditionsMain-Div {
    padding-left: 35px;
  }
  .imgsrc {
    height: 52vh;
  }
  .termsandconditionsmaintexth1-Div {
    height: 132px;
    width: 94%;
    display: flex;
    justify-content: end;
  }
  .register-next-button {
    width: 70%;
    display: flex;
    justify-content: center;
    margin-left: 13%;
  }
  .register-back-button {
    width: 70%;
    display: flex;
    justify-content: center;
    margin-left: 13%;
  }
}
@media (min-width: 767px) and (max-width: 999px) {
  .RegistrationDetailsImageUploader {
    display: block;
  }
  .RegistrationDetailsImageUploader-Mobile {
    display: none;
  }
  .registration-body {
    padding-left: 35px;
    padding-right: 35px;
  }
  .registration-header {
    height: 0px;
  }
  .termsandconditionsmaintexth1-Div {
    height: 167px;
  }
  .h1 {
    font-size: 28px;
    font-weight: 600;
  }
}
@media (min-width: 1000px) {
  .registration-body {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
  }
  .RegistrationDetailsImageUploader {
    display: block;
  }
  .RegistrationDetailsImageUploader-Mobile {
    display: none;
  }
}
.fIkhnN {
  background-color: #00277f;
}
