.share-button {
    /* background-color: #007bff; */
    color: black;
    border: none;
    /* padding: 10px 15px; */
    font-size: 16px;
    cursor: pointer;
  }
  
  .share-button i {
    margin-right: 5px;
  }
  
  /* .share-button:hover {
    background-color: #0069d9;
  }
   */