.testimonial-container{
    background: linear-gradient(to right, #e4ebfc 0%, #f5f8ff 100%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0px 100px 0px;
    gap: 20px;
    height: fit-content;
}

.testimonial__container {
    width: 100%;
}
.section__title{
    font-family: var(--extraBold);
    font-size: 35px;
    text-align: center;
    display: flex;
    align-items: end;
    justify-content: center;
    background: linear-gradient(45deg,#3b82f6, #9333ea);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 8px;
}
.section__subtitle{
    font-size: 16px;
    text-align: center;
    color: #00277f;
    margin-bottom: 20px;
}

.testimonial__card {
    background-color: #ffffff;
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: 30px;
}

.testimonial__img {
    width: 100px;
    height: 100px;
    object-fit: calc(200px);
    border-radius: 3rem;
    margin-bottom: 10px;
}

.testimonial__name {
    font-size:20px;
    font-weight: 900;
    margin-bottom: 25px;
    color: #00277f;
}

.testimonial__description {
    font-size: 120x;
    color: #00277f;
}

/* swipper class */
.swiper-pagination-bullet {
    background-color: red!important;
}

.swiper-pagination-bullet-active {
    background-color: red !important;
}

.container{
    height:fit-content;
}
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial__container {
        width: initial;
    }

    .testimonial__card {
        padding: 1.25rem 1.5rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {}

/* For small devices */
@media screen and (max-width: 350px) {}