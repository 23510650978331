.HDinsidePageContainer{
    width: 100%;
    height: 675px;
    background: linear-gradient(to right, #00277f 0%, #2059db 100%);
    color: #97b0f1;
}
.HDinsidePageContainerMain{
    width: 100%;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.HDinsidePageHeading{
    font-size: 44px;
    font-weight: 700;
}
.HDinsidePageText{
    width: 275px;
}
.HDinsidePageButton{
    width: 275px;
}
.HDinsidePageUnderText{
    width: 175px;
    font-size: 10px;
    display: flex;
    justify-content: space-evenly;
}
.HDinsidePageTextTab{
    width: 400px;
}
.HDinsidePageButtonTab{
    width: 400px;
}
.HDinsidePageContainerLap{
    max-width: 100%;
    background-image: url('../Assets/images/studentsit.jpg');
    background-size: cover;
    flex-direction: column;
    color: #cbd7f4;
}
.HDinsidePageContainerMainLap{
    width: 100%;
    height: 85vh;
    flex-direction: column;
    align-items: end;
    justify-content: space-evenly;
}
.HDinsidePageTextLap{
    font-size: large;
}
.HDinsidePageButtonLap{
    width: 190px;
}
.HDinsidePageLapContentDiv{
    width: 53%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}