.BlogSeconPageContainer{
    width: 100%;
    height: 2050px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.startSection{
    height: 98%;
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.FirstSection{
    height: 100%;
    width: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.FirstSectionImageDiv{
    background-size: 100% 100%;
    width: 100%;
    height: 20%;
}
.secondBlogImage{
    width: 100%;
    height: 100%;
}
.dateDiv{
    width: 100%;
    height: 1%;
    font-size: 11px;
    font-weight: 400;
    color: rgb(192, 190, 190);
    font-family: var(--medium);
    display: flex;
    justify-content: start;
    align-items: center;
}
.headingText{
    font-size: 24px;
    font-weight: 700;
}
.subHeddingText{
    font-size: 14px;
    font-weight: 500;
}
.paragraphText{
    font-size: 13px;
}
.FirstSectionSecondImageDiv{
    background-size: 100% 100%;
    width: 100%;
    height: 20%;
}
.SecondSection{
    height: 100%;
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.SecondSectionDiv{
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.SecondSectionSideHeading{
    font-size: small;
    color: #7b92cc;
}
.SecondSectionHeading{
    font-size: 24px;
    font-weight: 700;
}
.SecondSectionSubHeading{
    font-size: 14px;
    font-weight: 500;
}
.SecondSectionTime{
    font-size: 11px;
    font-weight: 400;
    color: rgb(192, 190, 190);

}
.BlogSeconPageContainerMob{
    width: 100%;
    height: 3700px;
}
.startSectionMob {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.FirstSectionMob {
    height: 54%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.FirstSectionImageDivMob {
    background-image: url(../Assets/images/happy-young-woman-sitting\ purple\ 1.jpg);
    background-size: 100% 100%;
    width: 100%;
    height: 9%;
}
.headingTextMob {
    font-size: 20px;
    font-weight: 700;
}
.paragraphTextMob {
    font-size: 12px;
}
.FirstSectionSecondImageDivMob {
    background-image: url(../Assets/images/studentsit.jpg);
    background-size: 100% 100%;
    width: 100%;
    height: 9%;
}
.SecondSectionMob {
    height: 45%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.SecondSectionHeadingMob {
    font-size: 18px;
    font-weight: 700;
}
.SecondSectionSubHeadingMob {
    font-size: 12px;
    font-weight: 500;
}
.SecondSectionDivMob {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.FirstSectionImageDivTab {
    background-image: url(../Assets/images/happy-young-woman-sitting\ purple\ 1.jpg);
    background-size: 100% 100%;
    width: 100%;
    height: 20%;
}
.FirstSectionSecondImageDivTab {
    background-image: url(../Assets/images/studentsit.jpg);
    background-size: 100% 100%;
    width: 100%;
    height: 20%;
}