.BlogFourth-first{ 
    width: 100%;
    height: 100vh;
    /* background-color: yellowgreen; */
    display: flex;
}
.BeginningImageText{ 
    width: 60%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
}
.HeaderImageFourth{ 
    width: 80%;
    height: 350px;
    background-image: url("https://d.newsweek.com/en/full/2096815/astronaut-spaceman-do-spacewalk.jpg");
    background-size: cover;
}
.FourthHeaderLinkingTexts{
    width: 40%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 10px;
}
.FourthTextsDiv{
    width: 70%;
    height: 23%;
    background-color: #e9e9e9;
    font-family: var(--bold);
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* background-color: cadetblue; */
    justify-content: space-between;

}
.HeaderImageTextsFourth{ 
    width: 80%;
    height: 316px;
    background-color: #e9e9e9;
    font-family: var(--medium   );
    font-size: 14px;
}
.FourthFirstHeadingBlog{ 
    width: 100%;
    height: 15%;
    /* background-color: blue; */
    font-family: var(--bold);
    font-size: 30px;
    text-align: center;

}
.FourthLikeShareComment{ 
    width: 100%;
    height: 13%;
    /* background-color: blue; */
    display: flex;
}
.Fourth-comment-div{ 
    width: 5%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: end;
    padding-top: 2px;
}
.Fourth-share-div{ 
    width: 3%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content:center;
    align-items: center;
    padding-bottom: 2px;
}
@media (min-width : 55px) and (max-width : 990px){
    .BlogFourth-first{ 
        width: 100%;
        height: 200vh;
        /* background-color: yellowgreen; */
        display: flex;
        flex-direction: column;
    }
    .BeginningImageText{ 
        width: 100%;
        height: 40%;
        /* background-color: yellow; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .HeaderImageFourth{ 
        width: 100%;
        height: 450px;
        background-image: url("https://d.newsweek.com/en/full/2096815/astronaut-spaceman-do-spacewalk.jpg");
        background-size: cover;
    }
    
    .HeaderImageTextsFourth{
        width: 100%;
        height: 60%;
        background-color: #e9e9e9;
        font-family: var(--medium   );
        font-size: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        /* justify-content: end; */
    }
    .FourthLikeShareComment{ 
       display: none;
    }
    .FourthHeaderLinkingTexts{ 
        width: 100%;
        height: 100%;
        /* background-color: red; */
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding-left: none;
    }
    .FourthTextsDiv{ 
        width: 310px;
        height: 24%;
        background-color: #e9e9e9;
        font-family: var(--bold);
        font-size: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
    
    }
}
@media (min-width : 320px) and (max-width : 730px){
    .HeaderImageFourth{
        width: 100%;
        height: 175px;
        background-image: url("https://d.newsweek.com/en/full/2096815/astronaut-spaceman-do-spacewalk.jpg");
        background-size: cover;
    }
}