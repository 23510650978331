.Contact-container {
  background: linear-gradient(to right, #e4ebfc 0%, #f5f8ff 100%);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (min-width: 1550px) and (max-width: 2000px) {
    width: 94%;
  }
}

.Contact-header {
  background: linear-gradient(to right, #e4ebfc 0%, #f5f8ff 100%);
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    height: 100px;
  }
}
.Contact-title {
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 33px;
  font-family: var(--extraBold);
  text-align: center;
  margin-bottom: 8px;
  @media screen and (max-width: 567px) {
    font-size: 26px;
    margin-bottom: 14px;
  }
}
.Contact-desc {
  text-align: center;
  font-size: 13px;
  font-family: var(--regular);
  line-height: 1.7rem;
  @media screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.contactForm-container {
  padding: 50px 250px 70px 250px;
  @media screen and (max-width: 576px) {
    padding: 30px 20px 30px 20px;
  }
  @media screen and (min-width: 576px) and (max-width: 1091px) {
    padding: 30px 80px 30px 80px;
  }
}

.custom-formLabel {
  font-family: var(--semiBold);
  margin-bottom: 0px;
}

.formBottom-txt {
  font-family: var(--semiBold);
  font-size: 11px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.agree-container {
  font-size: 11px;
  display: flex;
  width: fit-content;
  display: flex;
  justify-content: space-between;
}
.agree-container span {
  font-family: var(--semiBold);
  font-size: 11px;
}

.contactSubmit-btn {
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  color: #ffffff;
  font-family: var(--semiBold);
  width: 97%;
  border-radius: 50px;
  height: 40px;
  margin-left: 12px !important;
  outline: none !important;
}
// .contactSubmit-btn:hover {
  // background: linear-gradient(global.$primaryGradient2);
// }
