.navbar-container {
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 0px 100px 0px 100px;
  @media screen and (min-width: 320px) and (max-width: 780px) {
    justify-content: space-between;
    padding: 20px;
    height: 80px;
    direction: rtl;

  }
}
.logo-container{
  display: flex;
  gap: 20px;
  @media screen and (min-width: 320px) and (max-width: 780px) {
    margin-left: 60px;
  }

}
.header-logo1 {
  cursor: pointer;
  width: 50px;
  margin-top: 20px;
  @media screen and (max-width: 839px) {
    margin-top: 10px;
    width: 50px;
  }
}
.header-logo2 {
  cursor: pointer;
  width: 150px;
  margin-top: 20px;
  @media screen and (max-width: 839px) {
    margin-top: 10px;
    width: 50px;
  }
}
.header-option-container {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.options {
  color: #00277f;
  font-family: var(--semiBold);
  font-size: 13px;
  display: flex;
  gap: 40px;
  cursor: pointer;
  @media screen and (max-width: 972px) {
    font-size: 10px;
  }
  &:hover {
    color: #00277f;
    opacity: 0.5;
  }
}

.quote-nav-btn {
  width: 140px;
  height: 35px;
  background: linear-gradient(45deg,#3b82f6, #9333ea);
  font-family: var(--semiBold);
  font-size: 11px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  margin-left: 30px;
  outline: none !important;
  cursor: pointer;
  @media screen and (max-width: 989px) {
    font-size: 10px;
    width: 100px;
  }
  &:hover {
    font-size: 12px;
    // background: linear-gradient(global.$primaryGradient);
  }
}

// MOBILE

.toggler-div{
  display: none;
  @media (min-width : 55px) and (max-width : 990px){
    .toggler-div {
      width: 40px;
      height: 35px;
      background-color: #6e2fff;
      position: fixed;
      left: 0;
      top: 20px;
      border-radius: 6;
      border-radius: 0px 12px 12px 0px;
      display: grid;
      justify-content: center;
      align-content: center;
  }
  }
}
// .drawer-options-container {
//   list-style: none;
//   display: flex;
//   flex-direction: column;
//   padding: 0px;
//   gap: 20px;
// }
// .drawer-options {
//   text-decoration: none;
//   color: global.$White;
//   font-family: 
//   global.$Bold;
//   font-size: 15px;
// }
// .drawer-btn-container {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   font-family: global.$Bold;
//   color: global.$White;
// }
// .drawer-btn {
//   width: 90px;
//   height: 35px;
//   background-color: transparent;
//   font-family: global.$Bold;
//   font-size: 12px;
//   border: none;
//   border-radius: 6px;
//   border: 1px solid global.$White;
//   color: global.$White;
//   outline: none !important;
// }
