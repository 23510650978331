.modell{
    margin: 0;
    padding: 0;
    background-color: #00277f;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.search-bar{
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: end;
}
#search-id{
    border-radius: 7px 0px 0px 7px;
    border: none;
    height: 45px;
    width: 205px;
    background-color: white;
    outline: none;
    font-size: small;
    text-indent: 20px;
    font-weight: 600;

}

#serach-id{
    height: 45px;
    font-size:  small;
    width: 20%;
    border: none;
    border-radius: 0px 7px 7px 0px;
    margin-bottom: 0px;
    font-weight: 700;
    background-color: #8c9edb;

}
.tbutton-div{
    width: 100%;
    height: 10%; 
    display: flex;
    justify-content: center;
}
.trial-button{
    width: 280px;
    height: 45px;
    background-color: #8c9edb;
    border: none;
    font-size: small;
    font-weight: 600;
    border-radius: 8px;
}
.trial-button:hover{
    background-color: #8896c7;
    font-weight: 700;
}
button a{
    text-decoration: none;
    color: white;

}
.selection{
    width: 100%;
    height: 45%;
    font-size: xx-large;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    }
.active2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: #e9e9e9;
    height: 20px;
    width: 35%;
    text-decoration: none;
    transition: 0.5s;
    
    
}
.active2:hover{
    background-color: #6e2fff;
    font-weight: 700;
    border-radius: 5px;
    color: white;
    transition: 0.3s;
    height: 25px;
}
.home-div1{
    width: 100%;
    height: 10%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.hire-div1{
    width: 100%;
    height: 10%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;

}
.program-div1{
    width: 100%;
    height: 10%;
    /* background-color: red;   */
    display: flex;
    justify-content: center;
    align-items: center;
}
.blog-div1{
    width: 100%;
    height: 10vh;
    /* background-color: red;   */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.about-div1{
    width: 100%;
    height: 10%;
    /* background-color: red;   */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button2{
    height: 45px;
    width: 280px;
    border-radius: 7px;
    color: white;
    transition: 0.5s;
    background-color: transparent;
    border: 1px solid #6e2fff;
}
.button2:hover{
    background-color: #6e2fff;
    color: white;
}
.button2 a:hover{
    color: white;
}
.button2 a{
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
}
.button2-div{
    width: 100%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: end;
}
.close{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: end;
}
.close img{
    width: 40px;
    height: 40px;
}