.SecondPageStart{
    width: 100%;
    height: 80vh;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.BlogBoxesDiv{
    width: 100%;
    height: 65%;
    /* background-color: yellowgreen; */
    
    display: flex;
    justify-content: center;
    align-items: center;

}
.BlogBoxes{
    width: 76%;
    height: 95%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    
}
.BlogBox{
    width: 24%;
    height: 100%;
    background-color: #e9e9e9;
    border-radius: 12px;
}
.picture-div{
    width: 100%;
    height: 40%;
    background-image: url("https://media.istockphoto.com/id/1172427455/photo/beautiful-sunset-over-the-tropical-sea.jpg?s=170667a&w=0&k=20&c=Ljvlgzgq8F1nVhc8YJM15CFgwu0ZsJvVrRnZPLtn9oU=");
    background-size: cover;
    border-radius: 12px;
}
.picture-div2{
    width: 100%;
    height: 40%;
    background-image: url("https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
    background-size: cover;
    border-radius: 12px;
}
.picture-div3{
    width: 100%;
    height: 40%;
    background-image: url("https://www.deccanherald.com/sites/dh/files/styles/article_detail/public/articleimages/2022/12/31/kullu-pti-1176736-1672474267.jpg?itok=5XAvyIps");
    background-size: cover;
    border-radius: 12px;
}
.picture-div4{
    width: 100%;
    height: 40%;
    background-image: url("https://s30876.pcdn.co/wp-content/uploads/Switzerland-e1634207571492.jpg.optimal.jpg");
    background-size: cover;
    border-radius: 12px;
}
.BlogBoxHeader{
    width: 100%;
    height: 25%;
    /* background-color: aqua; */
    font-size: 16px;
    font-family: var(--bold);
}
.BlogBoxTextes{
    width: 100%;
    height: 35%;
    font-size: 10px;
    font-family: var(--bold);
    text-align: justify;

}
.AreaAddDiv{
    width: 100%;
    height: 30%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
}
.AreaAdd{
    width: 76%;
    height: 100%;
    background-color: #c9c9c9;
    font-family: var(--extraBold);
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width : 55px) and (max-width : 990px){
    .SecondPageStart{
        width: 100%;
        height: 200vh;
        /* background-color: aquamarine; */
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    .BlogBoxesDiv{
        width: 100%;
        height: 100%;
        /* background-color: yellowgreen; */
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .BlogBoxes{
        width: 100%;
        height: 100%;
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .AreaAddDiv{
       display: none;
    }
    .BlogBox{
        width: 300px;
        height: 24%;
        background-color: #e9e9e9;
        border-radius: 12px;
        text-align: center;
    }
    .BlogBoxTextes{
        width: 100%;
        height: 35%;
        font-size: 10px;
        font-family: var(--bold);
        text-align: center;
    
    }
}