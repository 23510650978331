.blogmain-first{
    width: 100%;
    height: 100vh;
    /* background-color: yellowgreen; */
    display: flex;
}
.start-image-text{
    width: 60%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
}
.header-image{
    width: 80%;
    height: 350px;
    background-image: url("../Assets/images/Skilled\ developer.jpg");
    background-size: cover;
}
.header-linking-texts{
    width: 40%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 10px;
}
.texts-div{
    width: 70%;
    height: 24%;
    background-color: #e9e9e9;
    font-family: var(--bold);
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* background-color: cadetblue; */
    justify-content: space-between;
    text-decoration: none;
    color: black;
}
.HeaderImageTexts{
    width: 80%;
    height: 316px;
    background-color: #e9e9e9;
    font-family: var(--medium   );
    font-size: 14px;
}
.FirstHeadingBlog{
    width: 100%;
    height: 15%;
    /* background-color: blue; */
    font-family: var(--bold);
    font-size: 30px;
    text-align: center;

}
.LikeShareComment{
    width: 100%;
    height: 13%;
    /* background-color: blue; */
    display: flex;
}
.comment-div{
    width: 5%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: end;
    padding-top: 2px;
}
.share-div{
    width: 3%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content:center;
    align-items: center;
    padding-bottom: 2px;
}
@media (min-width : 55px) and (max-width : 990px){
    .blogmain-first{
        width: 100%;
        height: 200vh;
        /* background-color: yellowgreen; */
        display: flex;
        flex-direction: column;
    }
    .start-image-text{
        width: 100%;
        height: 40%;
        /* background-color: yellow; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .header-linking-texts{
        /* display: none; */
    }
    .header-image{
        width: 100%;
        height: 450px;
        background-image: url("../Assets/images/Skilled\ developer.jpg");
        background-size: cover;
    }
    
    .HeaderImageTexts{
        width: 100%;
        height: 60%;
        background-color: #e9e9e9;
        font-family: var(--medium   );
        font-size: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        /* justify-content: end; */
    }
    .LikeShareComment{
       display: none;
    }
    .header-linking-texts{
        width: 100%;
        height: 100%;
        /* background-color: red; */
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding-left: none;
    }
    .texts-div{
        width: 310px;
        height: 24%;
        background-color: #e9e9e9;
        font-family: var(--bold);
        font-size: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
    
    }
}
@media (min-width : 320px) and (max-width : 730px){
    .header-image{
        width: 100%;
        height: 175px;
        background-image: url("../Assets/images/Skilled\ developer.jpg");
        background-size: cover;
    }
}