.nav-link {
  &:hover {
    background-color: #2059db;
  }
}
.picco-logo-small {
  width: 70px;
  height: auto;
  scale: 1.3;
}
.circular-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.bg-dark-300 {
  background-color: rgb(72, 72, 72);
}
.jGeaPm {
  height: 10%;
}
.jGeaPm .head-div {
  height: 1%;
}
.bVedOW {
  /* background: linear-gradient(to right, #00277f 0%, #2059db 50%) !important; */
  background-color: #00277f !important;
}
