.checkboxDivFreeTrialLap {
  display: flex;
  justify-content: space-between;
  width: 65%;
  align-items: end;
}
.PhoneInputInput {
  border: none;
  outline: none;
}
/* ::placeholder{
    color: #d3d3d3;
} */

.d-lg-flex {
  display: flex;
  justify-content: center;
}
.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center;
  font-weight: 400;
  font-size: unset;
}
.servicesFrrTrial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* height: 800px; */
  margin-top: 50px;
  height: 130vh;
}
.selectFreeTrial {
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
}
.headerSignIn {
  width: 100%;
}
.servicesMainDivSignInLap {
  height: 100%;
}

.PhoneInput {
  border: solid 1px #d3d3d3;
  border-radius: 2px;
  height: 30px;
}
.NextButton {
  color: white;
  font-weight: 700;
  font-size: 13px;
  background-color: #00277f;
}
/* server load korkam */
/* speed */
/* language */
/* framework     already set akye sannm   react  reactnative express*/
/* dynamic   python  javascrpt  after code run thenonly error see  */
/* static  java c++ c  compile cheyyum */
/* library import cheyth edkne */
/* framewor import cheyyende avshym lla */
