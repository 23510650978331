.fullpage-HDQandAsession{
    width: 100%;
    background-image: url('../Assets/images/studentStand.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    object-fit: cover;
}
.HDQandAsession-header{
    width: 100%;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.InputMainDiv{
    width: 55%;
    height: 15%;
}
.inputDiv{
    font-size: x-large;
    font-weight: 700;
    color: white;
}
.InputHDQandAsession{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: black;
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
}
.HDQandAsessionButton{
    width: 25%;
}
.slick-prev{
    opacity: 0;
}
.slick-next{
    opacity: 0;
}
.slick-slider {
    width: 79%;
}
.strigtLine{
    width: 100%;
    border: none;
    height: 2px;
    background-color: #abbff2;
}
.copyRight{
    text-align: center;
}
/* ::placeholder {
    color: #00277f;
  } */
.number{
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-small;
    font-weight: 500;
    color: rgb(236, 235, 235);
    border-radius: 5px;
}
.line{
    width: 60px;
    height: 1px;
    border: none;
}
.HDQandAsessionButtonDiv{
    width: 55%;
}
.HDQandAsessionStandCount{
    width: 55%;
    height: 10%;
    display: flex;
    align-items: center;

}
@media (min-width : 10px) and (max-width : 577px){
    .fullpage-HDQandAsession{
        background-image: none;
        background-color: #00277f;
    }
    .HDQandAsession-header{
        align-items: center;
    }
    .slick-slider {
        width: 100%;
    }
    .number{
        width: 20px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: xx-small;
        font-weight: 500;
        color: rgb(236, 235, 235);
        border-radius: 3px;
    }
    .HDQandAsessionButton{
        width: 100%;
    }
    .InputMainDiv{
        width: 78%;
    }
    .HDQandAsessionButtonDiv{
        width: 78%;
    }
    .HDQandAsessionStandCount{
        width: 78%;
    }
    .InputHDQandAsession{
        font-size: medium;
    }
    .inputDiv{
        font-size: larger;
    }
    .HDQandAsession-header{
        height: 500px;
    }

}
@media (min-width : 576px) and (max-width : 993px){
    .fullpage-HDQandAsession{
        background-image: none;
        background-color: #00277f;
    }
    .HDQandAsession-header{
        align-items: center;
    }
    .HDQandAsession-header{
        height: 500px;
    }
    .slick-slider {
        width: 100%;
    }
    .InputMainDiv{
        width: 60%;
    }
    .HDQandAsessionButtonDiv{
        width: 60%;
    }
    .HDQandAsessionButton{
        width: 100%;
    }
    .HDQandAsessionStandCount{
        width: 60%;
        height: 25%;
    }
}