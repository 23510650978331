.start{
    width: 100%;
    height: 100vh;
    background-image: url("../Assets/images/happy-young-woman-sitting\ purple\ 1.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    background-position: center;
}
.partial-div{
    width: 100%;
    height: 82%;
    /* background-color: red; */
}
.first-heading{
    width: 53.5%;
    height: 45%;
    /* background-color: aqua; */
    font-family: var(--extraBold);
    font-size: 42px;
    color: #6e2fff;
    display: flex;
    justify-content: end;
    align-items: end;
}
.first-text{
    width: 51%;
    height: 15%;
    /* background-color: yellow; */
    font-family: var(--semiBold);
    font-size: 11px;
    display: flex;
    justify-content: end;
    color: #00277f;
}
.button-hire-developer{
    width: 29%;
    height: 13%;
    /* background-color: green; */
    display: flex;
    justify-content: end;
    align-items: center;
    
}
.button-hire-developer Button{
    width: 180px;
    font-family: var(--semiBold);
}
.last-text{
    width: 32%;
    height: 3%;
    font-family: var(--semiBold);
    font-size: 9px;
    display: flex;
    justify-content: end;
}



@media (min-width : 55px) and (max-width : 990px){
    .start{
        width: 100%;
        /* height: 400px; */
        background: radial-gradient(53.2% 92.64% at 34.56% 41.34%, #FFFFFF 0%, #EEF3FF 100%);
        display: flex;
        flex-direction: column;
    }
    .first-heading{
        width: 100%;
        height: 25%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 25px;
    }
    .first-text{
        width: 100%;
        height: 30%;
        /* background-color: yellow; */
        font-family: var(--semiBold);
        text-align: center;
        font-size: 11px;
        display: flex;
        justify-content: center;
        color: #00277f;
    }
    .button-hire-developer{
        width: 100%;
        height: 25%;
        /* background-color: green; */
        display: flex;
        justify-content: center;
        align-items: end;
        
    }
    .last-text{
        width: 100%;
        height: 3%;
        /* background-color: yellowgreen; */
        font-family: var(--semiBold);
        font-size: 9px;
        display: flex;
        justify-content: center;
    }

}