.createBlogContainer {
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.createBlogMainDiv {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.createBlogHeading {
    font-size: 24px;
    font-weight: 700;
}

.createBlogTitleSection {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 17px;
    height: 15%;
}
.createBlogTitleInput{
    height: 40px;
    outline: none;
    border-radius: 8px;
    border-style: solid;
    border-color: gainsboro;
    padding-left: 25px;
}
.createBlogButton{
    color: white;
    background-color: blueviolet;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: none;
}
.popupContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 350px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px;
}
.popupIcone{
    font-size: 30px;
    display: flex;
    justify-content: center;
    color: rgb(243, 243, 42);

}

