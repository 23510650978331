
.App {
    max-width: 100%;
  }
/*    
   nav ul {
    display: flex;
    
  }    */
  .header{
    background-color: #00277f;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  .header h2{
    color: white;
  }
  .header h6{
    color: #7b92cc;
  }
  .services h3{
    color: blue;
    text-align: center;
  }
  .services{
    background-color: #f6f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 500px;
  }
  .servicesDiv{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 85%;
  }
  .continueDiv{
    display: flex;
    width: 100%;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    font-size: small;
    font-weight: bold;
    height: 40px;
    justify-content: space-evenly;
  }
  .continueDiv img{
    width: 32px;
  }
  .continueDiv div{
    width: 70%;
  }
  .UnderText{
    font-size: small;
    font-weight: 600;
  }
  .container{
    height: 650px;
  }
  .AppLap{
    height: 100vh;
    max-width: 100%;
  }
  .headerLap{
    width: 40%;
    background-color: #042060;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .servicesLap{
    width: 60%;
    background-color: #f6f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .headerLapDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 32%;
  }
  .headerLapDivImage{
    filter: invert(100%) sepia(100%) hue-rotate(200deg);
    width: 95px;
  }
  .headerLapDiv h4{
    color: white;
  }
  .headerLapDiv h6{
    color: #7b92cc;
  }
  .servicesLap h3{
    color: blue;
  }