@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Tektur:wght@500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap");
.title-main {
  color: #6e2fff;
  font-size: 38px;
}
.text-sm {
  font-size: 19px;
}
.bg-light-blue {
  background-color: #eef3ff;
}
.text-blue-dark {
  color: #00277f;
  font-size: 20px;
}
.font-extra-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.font-medium-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.font-small-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.explore-img-container {
  width: 100px;
  height: 100px;
}
.violet-blue {
  color: #3d03b8;
}
.bg-violet-blue {
  background-color: #1446b7;
}
.img-container-chooseus {
  height: 180px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.img-choose-us {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .3s ease;
}
.border-top-blue{
  background-color: white;
}
.border-top-blue::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  
  background-color: #939393;
  left: 0;
  top: 0;
}
.cstm-shadow{
  box-shadow: 0 3px 10px rgba(155, 155, 155, 0.2);
  cursor:  pointer;
  transition: transform .3s ease;
}
.cstm-shadow:hover{
  box-shadow: 0 3px 10px rgba(36, 36, 36, 0.2);
  transform: translateY(-8px);
}
.shadow-card{
  
  box-shadow: 0 3px 10px rgba(155, 155, 155, 0.2);
  cursor: pointer;
}
.shadow-card:hover .img-choose-us{
  transform: scale(1.1);
}
.bg-light{
  background-color: hsla(0, 0%, 98%, 0.911)
}
.sub-title{
  font-family: 'Montserrat', sans-serif;
  color: #2b56ba;
  font-weight: 600;
}
.text-blue-dark.font-medium-bold{
  font-style: italic;

}
.card-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}