.LoveButton button {
    background-color: transparent;
    border: none;
    color: #e74c3c;
    cursor: pointer;
    font-size: 24px;
    padding: 0;
  }
  
  .LoveButton .LoveCount {
    font-size: 12px;
    margin-left: 8px;
  }
  