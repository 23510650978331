.container-fluid{
    display: flex;
    justify-content: space-evenly;
}
.navbar-brand{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
} 
.navbar-brand img{
    width: 180px;
    height: 62px;
}
.logo2HD{
    width: 65%; 
    height: 100%;
    display: flex;
    justify-content: flex-start;
}
.navbar-collapse{
    width: 100%;
}
.navbar-nav{
    width: 75%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: end;
}
.navbar{
    display: flex;
    justify-content: space-between;
    height: 17vh;
    justify-content: end;
    align-items: end;
}
.selection-div{
    display: flex;
    flex-direction: row;
    width: 70%;
    height: 5vh;
    align-items: center; 
}
.home-div{
    width: 60px;
    height: 100%;
    text-decoration: none;
}
.programs-div{
    width: 65px;
    height: 100%;
    text-decoration: none;
}
.hire-div{
    width: 130px;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.blog-div{
    width: 50px;
    height: 100%;
    text-decoration: none;
}
.about-div{
    width: 60px;
    height: 100%;
    text-decoration: none; 
} 
.activeHD{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    color: #97b0f1;
}
.active:hover{
    background-color: #6e2fff;
    font-weight: 700;
    border-radius: 5px;
    color: white;
    transition: 0.9s;
    height: 23px;
}
#responsive-navbar-nav{
    display: flex;
    justify-content: end;
}
.ant-btn{
    width: 100%;
    height: 40px;
    background-color: #6e2fff;
}
.ant-btn:hover{
    font-weight: 500;
    background-color: #6e2fff;
}
.button-div{
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
 } 
 .button1HD{
    height: 40px;
    width: 130px;
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid #97b0f1;
    color: white;
}
.button1:hover{
    background-color: #6e2fff;
    color: white;
}
.button1 a:hover{
    color: white;
}
.button1 a{
    text-decoration: none;
    color: blue;
    width: 100%;
    height: 100%;
}
.signHD{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    font-weight: 600;
    color: white;
}
.toggler-div{
    display: none;
}
@media (min-width : 55px) and (max-width : 992px){
    .navbar-brand{
        display: none;
    }
    .toggler-div {
        width: 40px;
        height: 35px;
        background-color: #6e2fff;
        position: fixed;
        left: 0;
        top: 20px;
        border-radius: 6;
        border-radius: 0px 12px 12px 0px;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .selection-div{
        display: none;
    }
    .button-div{
        display: none;
    }
}
