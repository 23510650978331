body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(Assets/Fonts/Montserrat-Bold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: local('Montserrat-ExtraBold'), url(Assets/Fonts/Montserrat-ExtraBold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(Assets/Fonts/Montserrat-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(Assets/Fonts/Montserrat-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(Assets/Fonts/Montserrat-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Thin';
  src: local('Montserrat-Thin'), url(Assets/Fonts/Montserrat-Thin.ttf) format('opentype');
}

:root{
  --bold:"Montserrat-Bold";
  --semiBold:'Montserrat-SemiBold';
  --extraBold:"Montserrat-ExtraBold";
  --regular:"Montserrat-Regular";
  --thin:"Montserrat-Thin";
  --medium:"Montserrat-Medium";
}
::selection {
  color: #fff;
  background: #6a2afe;
}